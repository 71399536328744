import React from 'react';
import {
  Typography,
  Stack,
  Box,
  BoxProps,
  Skeleton,
  Button,
} from '@mui/material';
import { Circle, EmailTwoTone, Telegram, Textsms } from '@mui/icons-material';

import { useRouter } from '@cvt/hooks/useRouter';
import { DialogContext, FeedbackContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';

import { isNative } from '@shared/helpers/environment';
import { copyToClipboard } from '@shared/helpers/navigator';
import { getDateDotColor } from '@shared/helpers/date';
import { blue, darkBlue } from '@shared/theme/palette';

import { Authenticated } from '@modules/Auth/components';
import { sendSms } from '@modules/Contacts/helpers/mobile';
import { useChatCrud } from '@modules/Messaging/hooks/useChatCrud';
import { formatTripDates } from '@modules/Trips/helpers/date';

import { UserContext } from '../contexts';

interface Props extends BoxProps {
  user: Users.User;
}

export const UserLargeCard: React.FC<Props> = ({ user, ...props }) => {

  const { user: me } = React.useContext(UserContext);
  const { openDialog } = React.useContext(DialogContext);
  const { triggerFeedback } = React.useContext(FeedbackContext);

  const dictionary = useDictionary();
  const router = useRouter();

  const { createChat } = useChatCrud();

  const isUserConnected = React.useMemo(() => (user.connection?.type === 'general' || user.connection?.type === 'close') && user.connection?.status === 'active', [user]);

  const isTripHere = React.useMemo(() => {
    if (user.trip && me?.todayLocation) {
      return user.trip.location.id.toString() === me.todayLocation?.id.toString();
    }
    return false;
  }, [user.trip, me]);

  const circleColor = React.useMemo(() => {
    return user.trip ? getDateDotColor(user.trip.arrivalDatetime, user.trip.departureDatetime) : undefined;
  }, [user.trip]);

  const onChatRequest = React.useCallback(async (e: any) => {
    e.stopPropagation();
    const response = await createChat({
      users: [user.id],
    });
    router.inbox.view(response.data.id).go();
  }, [createChat, user, router]);

  const handleCopyToClipboard = async (text: string, feedbackText: string) => {
    await copyToClipboard(text);

    triggerFeedback({
      severity: 'success',
      message: feedbackText,
    });
  };

  const onSendMessage = React.useCallback(async () => {
    sendSms(`${user?.phone}`);
  }, [user?.phone]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      borderRadius={4}
      sx={{
        backgroundColor: blue,
        color: 'common.white',
        backgroundImage: `url(${user.profilePictureSizes?.[500]})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        overflow: 'hidden',
      }}
      {...props}
    >

      <Stack px={3} p={2} direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={1} direction="row">
          {user.thrUsername && (
            <Button color="primary" variant="contained" size="small" onClick={e => [e.stopPropagation(), openDialog('userQrCode', { userId: user?.id || '' })]}>
              @{user.thrUsername}
            </Button>
          )}
        </Stack>
      </Stack>
      <Box mt="auto" width="100%" p={2} pt={6} sx={theme => ({ background: `linear-gradient(to bottom, transparent, ${darkBlue})` })}>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Stack mt="auto" sx={{ textShadow: '0 0 4px rgba(0, 0, 0, 0.8)' }}>
            <Typography variant="h1" fontSize={32} fontWeight={600}>
              {user.displayName}
            </Typography>
            {user.trip?.arrivalDatetime && user.trip?.departureDatetime ? (
              <React.Fragment>
                {isTripHere && (
                  <Stack width="100%" direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1" fontWeight={500} color="primary.light" noWrap>
                      Here between {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                    </Typography>
                    {circleColor && (
                      <Circle fontSize="inherit" color={circleColor}/>
                    )}
                  </Stack>
                )}
                {!isTripHere && (
                  <Stack width="100%" direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1" fontWeight={500} color="primary.light" noWrap>
                      <strong>{user.trip.location.title}</strong> between {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                    </Typography>
                    {circleColor && (
                      <Circle fontSize="inherit" color={circleColor}/>
                    )}
                  </Stack>
                )}
              </React.Fragment>
            ) : (
              <Typography variant="body1" fontWeight={500} color="primary.light" noWrap>
                Currently in {user.baseLocation?.title || 'Unknown'}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Box
          pt={2}
        >
          <Box display="flex" gap={1} flexDirection="row" flexWrap="wrap">
            <Authenticated>
              {isUserConnected && (
                <Button variant="contained" color="primary" onClick={onChatRequest} startIcon={<Telegram/>}>
                  Message
                </Button>
              )}
            </Authenticated>
            {user.email && (
              <Button
                href={`mailto:${user.email}`}
                variant="contained"
                color="primary"
                startIcon={<EmailTwoTone/>}
                onClick={e => e.stopPropagation()}
              >
                Email
              </Button>
            )}
            {user.phone && (
              <Button onClick={e => [e.stopPropagation(), isNative() ? onSendMessage() : handleCopyToClipboard(user?.phone || '', dictionary.users.profile.clipboardSaved('Phone number'))]} variant="contained" color="primary" startIcon={<Textsms/>}>{isNative() ? 'SMS' : 'Phone'}</Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const UserLargeCardSkeleton: React.FC = () => {
  return (
    <Box
      height="100%"
      borderRadius={4}
      display="flex"
      sx={{
        backgroundColor: blue,
        overflow: 'hidden',
      }}
    >
      <Skeleton variant="rectangular" height="100%" width="100%" />
    </Box>
  );
};
