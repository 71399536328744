import React from 'react';
import { Brightness4, Brightness7, Close, Logout, QrCodeScanner, Translate, Visibility } from '@mui/icons-material';
import {
  alpha,
  Avatar,
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { groupBy, noop } from 'lodash';

import { DialogContext, LocalizationContext } from '@cvt/contexts';
import { NavLink } from '@cvt/components/NavLink';
import { LanguageSelect } from '@cvt/components/LanguageSelect';
import { useRouter } from '@cvt/hooks/useRouter';

import config from '@shared/config';
import { navigation } from '@shared/routes';
import { SettingsContext } from '@shared/contexts';

import { AuthContext } from '@modules/Auth/contexts';
import { UserContext } from '@modules/Users/contexts';
import { Authenticated, NotAuthenticated } from '@modules/Auth/components';
import { useNotifications } from '@modules/Notifications/hooks/useNotifications';

type NavigationItemProps = {
  item: CVT.Navigation.NavigationItem;
  onCloseRequest: () => void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ item, onCloseRequest }) => {
  if(!!item.children) {
    return null;
  }
  if (item.divider) {
    return <Divider component="li" />;
  }
  if (!!item.route) {
    return (
      <MenuItem component={NavLink} to={item.route} onClick={onCloseRequest}>
        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
        <ListItemText primary={item.text} />
      </MenuItem>
    );
  }
  if (!!item.onClick) {
    return (
      <MenuItem onClick={() => [item.onClick && item.onClick(), onCloseRequest()]}>
        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
        <ListItemText primary={item.text} /> 
      </MenuItem>
    );
  }
  return null;
};

type Props = React.PropsWithChildren<{
  isOpen: boolean;
  onCloseRequest: () => void;
}>;

export const UserMenu: React.FC<Props> = ({ children, isOpen, onCloseRequest }) => {
  const router = useRouter();
  const { logout, isLoggedIn } = React.useContext(AuthContext);
  const { user: me, unreadMessages } = React.useContext(UserContext);
  const { dictionary } = React.useContext(LocalizationContext);
  const { mode, toggleMode } = React.useContext(SettingsContext);
  const { openDialog } = React.useContext(DialogContext);

  const { notifications } = useNotifications({ limit: 20 }, {
    enabled: isLoggedIn,
  });

  const newNotificationsCounter = React.useMemo(() => notifications.filter(notification => !notification.readAt).length, [notifications]);
  const ToggleModeIcon = mode === 'dark' ? Brightness7 : Brightness4;

  const userMenuRoutes = React.useMemo(() => {
    return navigation(router, dictionary, config, unreadMessages, newNotificationsCounter, me?.id).userMenu || [];
  }, [router, dictionary, me?.id, unreadMessages, newNotificationsCounter]);

  const { authenticatedNavRoutes, notAuthenticatedNavRoutes, otherNavRoutes } = React.useMemo(() => {
    const groups = groupBy(userMenuRoutes, (item) => {
      if (item.requiresAuth === true) return 'authenticated';
      if (item.requiresAuth === false) return 'notAuthenticated';
      return 'other';
    });
    return {
      authenticatedNavRoutes: groups.authenticated || [],
      notAuthenticatedNavRoutes: groups.notAuthenticated || [],
      otherNavRoutes: groups.other || [],
    };
  }, [userMenuRoutes]);

  return (
    <React.Fragment>
      {children}
      <SwipeableDrawer
        open={isOpen}
        onOpen={noop}
        onClose={onCloseRequest}
        anchor="right"
        PaperProps={{
          sx: theme => ({
            paddingTop: 'env(safe-area-inset-top)',
            width: {
              xs: '100%',
              md: 320,
            },
            backgroundColor: mode === 'dark' ? alpha(theme.palette.secondary.main, 0.2) : alpha(theme.palette.grey[200], 0.6),
            'backdrop-filter': 'blur(5px)',
          }),
        }}
      >
        <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
          <IconButton
            aria-label="close"
            onClick={onCloseRequest}
          >
            <Close/>
          </IconButton>
          {me && (
            <IconButton aria-label="user qr code" onClick={() => openDialog('userQrCode', { userId: me?.id || '' })} sx={{ ml: -1 }}>
              <QrCodeScanner fontSize="medium"/>
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} alignItems="center">
            {me && (
              <Stack direction="column" spacing={1} justifyContent="center" alignItems="center" onClick={() => [router.users.view(me?.thrUsername || me?.id).go(), onCloseRequest()]}>
                <Avatar
                  src={me.profilePictureSizes?.[100]}
                  alt={me.displayName} sx={{ width: 100, height: 100 }}
                />
                <Stack direction="column" justifyContent="center" alignItems="center">
                  <Typography variant="h1" component="span" fontWeight="500" textAlign="center">{me.displayName}</Typography>
                  <Typography variant="body1" component="span" textAlign="center">{(me.thrUsername && `@${me.thrUsername}`) || me.email}</Typography>
                </Stack>
              </Stack>
            )}
            <Authenticated>
              <Paper component={Box} width="100%">
                <List>
                  {me && (
                    <NavigationItem item={{ icon: <Visibility fontSize="small"/>, text: 'View Profile', route: router.users.view(me?.thrUsername || me?.id).path }} onCloseRequest={onCloseRequest} />
                  )}
                  {authenticatedNavRoutes.map((item, idx) => <NavigationItem key={idx} item={item} onCloseRequest={onCloseRequest} />)}
                </List>
              </Paper>
            </Authenticated>
            <Paper component={Box} width="100%">
              <List>
                <NotAuthenticated>
                  {notAuthenticatedNavRoutes.map((item, idx) => <NavigationItem key={idx} item={item} onCloseRequest={onCloseRequest} />)}
                </NotAuthenticated>
                {otherNavRoutes.map((item, idx) => <NavigationItem key={idx} item={item} onCloseRequest={onCloseRequest} />)}
                {(config.featureFlags.lightMode || config.featureFlags.translations) && (
                  <Divider />
                )}
                {config.featureFlags.lightMode && (
                  <MenuItem onClick={() => [toggleMode(), onCloseRequest()]}>
                    <ListItemIcon><ToggleModeIcon/></ListItemIcon>
                    <ListItemText>{mode === 'light' ? dictionary.header.switchToDarkMode : dictionary.header.switchToLightMode}</ListItemText>
                  </MenuItem>
                )}
                {config.featureFlags.translations && (
                  <MenuItem>
                    <ListItemIcon><Translate/></ListItemIcon>
                    <ListItemText><LanguageSelect onChange={onCloseRequest}/></ListItemText>
                  </MenuItem>
                )}
                <Authenticated>
                  <Divider />
                  <NavigationItem
                    item={{
                      icon: <Logout fontSize="small"/>,
                      text: dictionary.menu.auth.logout,
                      onClick: logout,
                    }}
                    onCloseRequest={onCloseRequest}
                  />
                </Authenticated>
              </List>
            </Paper>
          </Stack>
        </DialogContent>
      </SwipeableDrawer>
    </React.Fragment>
  );
};
