import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Contacts } from '@capacitor-community/contacts';

import { useDictionary } from '@cvt/hooks/useDictionary';
import { emailRegex } from '@cvt/helpers/validation';

import { PictureUploader } from '@shared/components/PictureUploader';

import { SearchAddressAutocomplete } from '@modules/Locations/components/SearchAddressAutocomplete';
import { getConnectionLabel } from '@shared/helpers/connection';

import { ContactConnectionToggleField } from '../components/ContactConnectionToggleField';
import { contactPhoneRegex } from '../helpers/validation';

type FormCrud = Omit<Contacts.Crud, 'location'> & {
  location: any;
};

type Fields = keyof (FormCrud & {
  submit: boolean;
});


interface Props {
  defaultValues?: Partial<FormCrud>;
  onSubmitRequest: (values: Contacts.Crud) => void;
  onSubmitButtonText: string;
  disabledFields?: Array<Fields>;
  hiddenFields?: Array<Fields>;
}

const DEFAULT_VALUES: Partial<FormCrud> = {
  firstName: '',
  lastName: '',
  email: '',
};



export const ContactForm: React.FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText,  disabledFields, hiddenFields }) => {
  const dictionary = useDictionary();

  const { handleSubmit, control, formState: { isSubmitting, isDirty }, setValue } = useForm<FormCrud>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const isFieldDisabled = React.useCallback((field: Fields) => {
    if (!disabledFields) {
      return false;
    }
    return disabledFields.indexOf(field) !== -1;
  }, [disabledFields]);

  const isFieldVisible = React.useCallback((field: Fields) => {
    if (!hiddenFields) {
      return true;
    }
    return hiddenFields.indexOf(field) === -1;
  }, [hiddenFields]);

  const onSubmit = React.useCallback(async (data: FormCrud) => {
    try {
      await onSubmitRequest({
        ...data,
        location: data.location ? {
          title: data.location.name,
          latitude: data.location.location.lat,
          longitude: data.location.location.lng,
        } : null,
        sorted: !!data.connectionType,
      });
    } catch (err) {
      console.error(err);
    }
  }, [onSubmitRequest]);

  const getImage = React.useCallback(async () => {
    if (defaultValues.externalId) {

      const result = await Contacts.getContact({
        contactId: defaultValues.externalId,
        projection: {
          image: true,
        },
      });

      if (result.contact.image) {
        try {
          // If the base64 string includes the data URL prefix, remove it.
          const base64String = result.contact.image?.base64String?.startsWith('data:image/')
            ? result.contact.image.base64String.split(',')[1]
            : result.contact.image.base64String;
  
          // Convert base64 to Uint8Array
          const binaryString = atob(base64String as string);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
  
          // Create a Blob from the Uint8Array
          const blob = new Blob([bytes], { type: 'image/jpeg' }); // Adjust MIME type if necessary
  
          // Create File object from Blob
          const file = new File([blob], `contact_image_${defaultValues.externalId}.jpg`, { type: 'image/jpeg' });
  
          // Now you can call setImage with the File object
          setValue('image', file as unknown as any);
        } catch (err) {
          console.error('Couldn\'t import image');
        }
      }
    }
  }, [defaultValues.externalId, setValue]);

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      (event.target as HTMLInputElement).blur();
    }
  };

  React.useEffect(() => {
    getImage();
  }, [getImage]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {isFieldVisible('image') && (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Controller
              name="image"
              control={control}
              render={({ field, fieldState }) => (
                <Box display="flex" justifyContent="center">
                  <PictureUploader
                    {...field}
                    disabled={isFieldDisabled('image')}
                    label={dictionary.forms.contact.fieldPicture}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                </Box>
              )}
            />
          </Grid>
        )}
        {isFieldVisible('firstName') && (
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.contact.fieldName}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('firstName')}
                  size="small"
                  onKeyDown={handleEnterPress}
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('lastName') && (
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.contact.fieldLastName}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('lastName')}
                  size="small"
                  onKeyDown={handleEnterPress}
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('cel') && (
          <Grid item xs={6}>
            <Controller
              name="cel"
              control={control}
              rules={{
                pattern: {
                  value: contactPhoneRegex,
                  message: dictionary.forms.validations.invalidPhone,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.contact.fieldMobilePhone}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('cel')}
                  size="small"
                  onKeyDown={handleEnterPress}
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('tel') && (
          <Grid item xs={6}>
            <Controller
              name="tel"
              control={control}
              rules={{
                pattern: {
                  value: contactPhoneRegex,
                  message: dictionary.forms.validations.invalidPhone,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.fieldPhone}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('tel')}
                  size="small"
                  onKeyDown={handleEnterPress}
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('email') && (
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value: emailRegex,
                  message: dictionary.forms.validations.invalidEmail,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.fieldEmail}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('email') || !!defaultValues.email}
                  inputProps={{
                    autoCapitalize: 'none',
                  }}
                  size="small"
                  onKeyDown={handleEnterPress}
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('location') && (
          <Grid item xs={12}>
            <Controller
              name="location"
              control={control}
              render={({ field, fieldState }) => (
                <SearchAddressAutocomplete
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.contact.fieldLocation}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  TextFieldProps={{
                    size: 'small',
                    error: !!fieldState.error,
                    helperText: fieldState.error?.message,
                  }}
                  disabled={isFieldDisabled('location')}
                />
              )}
            />
          </Grid>
        )}

        {isFieldVisible('connectionType') && (
          <Grid item xs={12}>
            <Controller
              name="connectionType"
              control={control}
              render={({ field, fieldState }) => (
                <ContactConnectionToggleField
                  label={getConnectionLabel(dictionary, field.value || 'hidden')}
                  value={field.value || 'hidden'}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  hiddenFields={['public']}
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('note') && (
          <Grid item xs={12}>
            <Controller
              name="note"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  rows={2}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.fieldNote}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  inputProps={{
                    autoCapitalize: 'none',
                  }}
                  size="small"
                  onKeyDown={handleEnterPress}
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('submit') && (
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isFieldDisabled('submit') || !isDirty}
            >
              {onSubmitButtonText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
