import React, { Suspense } from 'react';
import { useEvent, useMount } from 'react-use';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { App as CapacitorApp } from '@capacitor/app';

import { useRouter } from '@cvt/hooks/useRouter';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';

import { AuthContext } from '@modules/Auth/contexts';
import { Authenticated, NotAuthenticated } from '@modules/Auth/components';
import { Impersonate } from '@modules/Auth/views';
import { useMobileNotifications } from '@modules/Notifications/hooks/useMobileNotifications';
import { communitiesClient } from '@modules/Communities/client/communitiesClient';
import { cacheKeys as communitiesCacheKeys } from '@modules/Communities/config';
import { tripsClient } from '@modules/Trips/client/tripClient';
import { cacheKeys as tripsCacheKeys } from '@modules/Trips/config';
import { UserContext } from '@modules/Users/contexts';
import { notificationsClient } from '@modules/Notifications/client/notificationsClient';
import { cacheKeys as notificationsCacheKeys } from '@modules/Notifications/config';
import { messagingClient } from '@modules/Messaging/client/messagingClient';
import { cacheKeys as messagingCacheKeys } from '@modules/Messaging/config';
import { cacheKeys as usersCacheKeys } from '@modules/Users/config';
import { userClient } from '@modules/Users/client/userClient';

import { trackPageview } from '@shared/tracking';
import { SwipableUsers } from '@modules/Users/components/SwipableUsers';

const SwipableTrips = React.lazy(() => import('./Trips/SwipableTrips'));
const SwipableAlerts = React.lazy(() => import('./Notifications/SwipableAlerts'));
const SwipableEvents = React.lazy(() => import('./Events/SwipableEvents'));
const SwipableContacts = React.lazy(() => import('./Contacts/SwipableContacts'));

const DashboardTrips = React.lazy(() => import('./Trips/DashboardTrips'));
const DashboardUsers = React.lazy(() => import('./Users/DashboardUsers'));
const DashboardEvents = React.lazy(() => import('./Events/DashboardEvents'));

const RecentlyConnectedUsers = React.lazy(() => import('./Users/RecentlyConnectedUsers'));

const Login = React.lazy(() => import('./Auth/Login'));
const EricContactsLogin = React.lazy(() => import('./Auth/EricContactsLogin'));
const Signup = React.lazy(() => import('./Auth/Signup'));
const ResetPassword = React.lazy(() => import('./Auth/ResetPassword'));
const Onboarding = React.lazy(() => import('./Users/Onboarding'));
const PhoneVerification = React.lazy(() => import('./Auth/PhoneVerification'));
const MapExperience = React.lazy(() => import('./Auth/Experience/MapExperience'));
const InvitationExperience = React.lazy(() => import('./Auth/Experience/InvitationExperience'));
const CommunityInvitationExperience = React.lazy(() => import('./Auth/Experience/CommunityInvitationExperience'));

const Profile = React.lazy(() => import('./Users/Profile'));
const Notifications = React.lazy(() => import('./Notifications/Notifications'));
const Search = React.lazy(() => import('./Search'));

const Home = React.lazy(() => import('./Home/Home'));
const Trips = React.lazy(() => import('./Trips/Trips'));
const TripNotification = React.lazy(() => import('./Trips/TripNotification'));
const Contacts = React.lazy(() => import('./Contacts/Contacts'));

const Around = React.lazy(() => import('./Around'));
const Spheres = React.lazy(() => import('./Spheres/Spheres'));
const Communities = React.lazy(() => import('./Communities/Communities'));
const ViewCommunity = React.lazy(() => import('./Communities/ViewCommunity'));
const EditCommunity = React.lazy(() => import('./Communities/EditCommunity'));
const ViewCommunityDetails = React.lazy(() => import('./Communities/ViewCommunityDetails'));
const ViewCommunityGeneralGroup = React.lazy(() => import('./Communities/ViewCommunityGeneralGroup'));
const ViewCommunityChannel = React.lazy(() => import('./Communities/ViewCommunityChannel'));
const ViewCommunityChannelDetails = React.lazy(() => import('./Communities/ViewCommunityChannelDetails'));
const EditCommunityChannel = React.lazy(() => import('./Communities/EditCommunityChannel'));
const Users = React.lazy(() => import('./Users/Users'));
const ViewUser = React.lazy(() => import('./Users/ViewUser'));
const Events = React.lazy(() => import('./Events/Events'));
const Network = React.lazy(() => import('./Network/Network'));

const ViewPublicCommunity = React.lazy(() => import('./Communities/ViewPublicCommunity'));
const ViewPublicEvent = React.lazy(() => import('./Communities/ViewPublicEvent'));

const Integrations = React.lazy(() => import('./Integrations/Integrations'));
const IntegrationSuccess = React.lazy(() => import('./Integrations/IntegrationSuccess'));

const Inbox = React.lazy(() => import('./Inbox/Inbox'));
const ViewChat = React.lazy(() => import('./Inbox/ViewChat'));
const EditChat = React.lazy(() => import('./Inbox/EditChat'));

const Feedback = React.lazy(() => import('./Feedback'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = React.lazy(() => import('./Terms'));



export const Root = () => {
  const { loading, isLoggedIn } = React.useContext(AuthContext);
  const { user } = React.useContext(UserContext);
  const router = useRouter();
  const location = useLocation();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    trackPageview();
  }, [location]);

  const { initNotifications } = useMobileNotifications();

  React.useEffect(() => {
    if (isLoggedIn) {
      initNotifications();


      // PREFETCH MAIN QUERIES

      queryClient.prefetchQuery({
        queryKey: [communitiesCacheKeys.getCommunities, {
          limit: 5000,
        }],
        queryFn: () => communitiesClient.getCommunities({
          limit: 5000,
        }),
      });

      queryClient.prefetchQuery({
        queryKey: [tripsCacheKeys.getTrips, {
          user: user?.id,
        }],
        queryFn: () => tripsClient.getTrips({
          user: user?.id,
        }),
      });
  
      queryClient.prefetchQuery({
        queryKey: [messagingCacheKeys.getChats, {
          limit: 5000,
          archived: false,
        }],
        queryFn: () => messagingClient.getChats({
          limit: 5000,
          archived: false,
        }),
      });

      queryClient.prefetchInfiniteQuery({
        queryKey: [usersCacheKeys.getUsers, 500],
        queryFn: () => userClient.getUsers({
          limit: 500,
        }),
      });

      queryClient.prefetchInfiniteQuery({
        queryKey: [notificationsCacheKeys.getNotifications, 20],
        queryFn: () => notificationsClient.getNotifications({
          limit: 20,
        }),
      });
    }
  }, [isLoggedIn, user, initNotifications, queryClient]);

  useMount(() => {
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if(!canGoBack){
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });
  });

  useEvent('openFeedback', () => router.feedback.go());

  if (loading) {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <Suspense fallback={<BodyLoading/>}>
      <NotAuthenticated>
        <Routes>
          <Route path={router.auth.login.path} element={<Login/>}/>
          <Route path={router.auth.ericContactsLogin.path} element={<EricContactsLogin/>}/>
          <Route path={router.auth.resetPassword.path} element={<ResetPassword/>}/>
          <Route path={router.auth.signup.path} element={<Signup/>}/>
          <Route path={router.communities.view().path} element={<ViewPublicCommunity/>}/>
          <Route path={router.communities.view().channel().auth.path} element={<ViewPublicEvent/>}/>
          <Route path={router.invitation.community().path} element={<CommunityInvitationExperience/>}/>
          <Route path={router.auth.impersonate.path} element={<Impersonate/>}/>
          <Route path={router.users.view().path} element={<ViewUser/>}/>
          <Route path={router.invitation.invite().path} element={<InvitationExperience/>}/>
          <Route path={router.privacyPolicy.path} element={<PrivacyPolicy/>}/>
          <Route path={router.termsAndConditions.path} element={<TermsAndConditions/>}/>
          <Route path={router.integrations.success().path} element={<IntegrationSuccess/>}/>
          <Route path={router.integrations.path} element={<IntegrationSuccess/>}/>
          {/*<Route path={router.auth.experience.network.path} element={<MapExperience/>}/>*/}
          <Route path={router.auth.experience.communities.path} element={<MapExperience/>}/>
          <Route path={router.auth.experience.profile.path} element={<MapExperience/>}/>
          <Route path={router.feedback.path} element={<Feedback/>}/>
          <Route path="*" element={<Navigate to={router.auth.login.path}/>}/>
        </Routes>
      </NotAuthenticated>
      <Authenticated>
        <Routes>
          <Route path={router.home.path} element={<Home/>}/>
          <Route path={router.around.path} element={<Around/>}/>
          <Route path={router.dashboard.alerts.swipe.path} element={<SwipableAlerts/>}/>
          <Route path={router.dashboard.users.swipe.path} element={<SwipableUsers/>}/>
          <Route path={router.dashboard.users.recent.path} element={<RecentlyConnectedUsers/>}/>
          <Route path={router.dashboard.users.path} element={<DashboardUsers/>}/>
          <Route path={router.dashboard.trips.swipe.path} element={<SwipableTrips/>}/>
          <Route path={router.dashboard.trips.path} element={<DashboardTrips/>}/>
          <Route path={router.dashboard.events.path} element={<DashboardEvents/>}/>
          <Route path={router.dashboard.events.swipe.path} element={<SwipableEvents/>}/>
          <Route path={router.dashboard.contacts.swipe.path} element={<SwipableContacts/>}/>
          <Route path={router.trips.path} element={<Trips/>}/>
          <Route path={router.trips.edit().notifications.path} element={<TripNotification/>}/>
          <Route path={router.users.view().path} element={<ViewUser/>}/>
          <Route path={router.networks.path} element={<Users/>}/>
          <Route path={router.network.path} element={<Network/>}/>
          <Route path={router.contacts.path} element={<Contacts/>}/>
          <Route path={router.spheres.tab().path} element={<Spheres/>}/>
          <Route path={router.communities.path} element={<Communities/>}/>
          <Route path={router.communities.view().details.path} element={<ViewCommunityDetails/>}/>
          <Route path={router.communities.view().general.path} element={<ViewCommunityGeneralGroup/>}/>
          <Route path={router.communities.view().channel().path} element={<ViewCommunityChannel/>}/>
          <Route path={router.communities.view().channel().auth.path} element={<ViewCommunityChannel/>}/>
          <Route path={router.communities.view().channel().details.path} element={<ViewCommunityChannelDetails/>}/>
          <Route path={router.communities.view().editChannel().path} element={<EditCommunityChannel/>}/>
          <Route path={router.communities.view().path} element={<ViewCommunity/>}/>
          <Route path={router.communities.edit().path} element={<EditCommunity/>}/>
          <Route path={router.inbox.tab().path} element={<Inbox/>}/>
          <Route path={router.inbox.view().path} element={<ViewChat/>}/>
          <Route path={router.inbox.edit().path} element={<EditChat/>}/>
          <Route path={router.onboarding.step().path} element={<Onboarding/>}/>
          <Route path={router.phoneVerification.path} element={<PhoneVerification/>}/>
          <Route path={router.profile.tab().path} element={<Profile/>}/>
          <Route path={router.notifications.path} element={<Notifications/>}/>
          <Route path={router.integrations.success().path} element={<IntegrationSuccess/>}/>
          <Route path={router.integrations.path} element={<Integrations/>}/>
          <Route path={router.auth.impersonate.path} element={<Impersonate/>}/>
          <Route path={router.privacyPolicy.path} element={<PrivacyPolicy/>}/>
          <Route path={router.termsAndConditions.path} element={<TermsAndConditions/>}/>
          <Route path={router.search.tab().path} element={<Search/>}/>
          <Route path={router.feedback.path} element={<Feedback/>}/>
          <Route path={router.events.path} element={<Events/>}/>
          <Route path="*" element={<Navigate to={router.home.path}/>}/>
        </Routes>
      </Authenticated>
    </Suspense>
  );
};
