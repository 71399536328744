import React from 'react';
import noop from 'lodash/noop';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { DialogContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { isNative } from '@shared/helpers/environment';

import { EditContact } from '../partials/EditContact';
import { useContact } from '../hooks/useContact';
import { useContactCrud } from '../hooks/useContactCrud';

type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    contactId: number;
  }
}

export const EditContactDialog: React.FC<Props> = ({ open, onClose, data }) => {

  const dictionary = useDictionary();
  const { asyncConfirmation } = React.useContext(DialogContext);

  const { contact, status } = useContact({
    id: data?.contactId as number,
  }, {
    enabled: !!data?.contactId,
  });


  const { deleteContact } = useContactCrud();

  const onDelete = React.useCallback(async () => {
    const userConfirmed = await asyncConfirmation({ title: dictionary.contacts.edit.deleteConfirmation, content: ' ' });
    if (!contact || !userConfirmed) {
      return false;
    }
    return deleteContact(contact.id).then(onClose);
  }, [contact, deleteContact, asyncConfirmation, dictionary, onClose]);

  if (!data?.contactId) {
    return null;
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          maxHeight: 'calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top))',
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">{dictionary.contacts.edit.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box pt={1}>
          {(status === 'loading' || !contact) &&  <BodyLoading height={300}/>}
          {status === 'success' && contact && (
            <React.Fragment>
              <EditContact
                contact={contact}
                onSuccess={onClose}
              />
              <Box pt={1}>
                <Button fullWidth variant="text" color="primary" onClick={onDelete}>
                  {dictionary.trips.edit.buttonDelete}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
    </SwipeableDrawer>
  );
};
