import { ContactPayload, Contacts, PhonePayload, PhoneType } from '@capacitor-community/contacts';
import { SmsManager } from '@byteowls/capacitor-sms';
import * as Sentry from '@sentry/react';
import { locationClient } from '@modules/Locations/clients/locationClient';

const getPhone = (phones: PhonePayload[] = [], type: PhoneType, useFallback: boolean = false) => {
  const phone = phones.find(it => it.type === type);
  if (phone) {
    return phone.number;
  }

  if (useFallback) {
    return phones[0]?.number;
  }

  return null;
};

export const mapContact = async (contact: ContactPayload): Promise<Omit<Contacts.Crud, 'owner'>> => {
  try {
    const address = contact.postalAddresses?.filter(address => address.city && address.country)[0];
    let location: CVT.MaybeNull<any> = null;
    if (address) {
      const locations = await locationClient.searchAddress(`${address.city}, ${address.region}, ${address.country}`);
      location = locations.data[0];
    }
    return {
      firstName: contact.name?.given || contact.name?.display || 'Unknown',
      lastName: contact.name?.family || null,
      location: location ? {
        title: location.name,
        latitude: location.location.lat,
        longitude: location.location.lng,
      } : null,
      // address: (contact?.postalAddresses && `${contact?.postalAddresses[0]?.country || ''}, ${contact?.postalAddresses[0]?.city || ''} ${contact?.postalAddresses[0]?.street || ''} ${contact?.postalAddresses[0]?.postcode || ''} ${contact?.postalAddresses[0]?.region || ''}`) || null,
      image: undefined,
      invitedAt: null,
      email: (contact.emails && contact.emails[0]?.address) || null,
      tel: getPhone(contact.phones, PhoneType.Home),
      cel: getPhone(contact.phones?.filter(it => it.type !== PhoneType.Home), PhoneType.Mobile, true),
      externalId: contact.contactId,
      sorted: false,
      connectionType: null,
      note: contact.note || null,
    };
  } catch (err) {
    Sentry.captureException(err);
    return {
      firstName: 'Unknown',
      lastName: null,
      location: null,
      // address: null,
      image: undefined,
      invitedAt: null,
      email: null,
      tel: null,
      cel: null,
      externalId: contact.contactId,
      sorted: false,
      connectionType: null,
      note: contact.note || null,
    };
  }
};

export const mapContactPickerContact = (contact: Contacts.ContactPickerContact): Omit<Contacts.Crud, 'owner'> => {
  try {
    return {
      firstName: contact.name[0] || 'Unknown',
      lastName: null,
      location: null,
      // address: null,
      image: undefined,
      invitedAt: null,
      email: contact.email[0] || null,
      tel: contact.tel[1] || contact.tel[2] || contact.tel[3] || '',
      cel: contact.tel[0] || '',
      externalId: '',
      sorted: false,
      connectionType: null,
      note: null,
    };
  } catch (err) {
    Sentry.captureException(err);
    return {
      firstName: 'Unknown',
      lastName: null,
      location: null,
      // address: null,
      image: undefined,
      invitedAt: null,
      email: null,
      tel: null,
      cel: null,
      externalId: '',
      sorted: false,
      connectionType: null,
      note: null,
    };
  }
};

export const sendSms = (smsNumber: string, smsText: string = ' ') => {
  return SmsManager.send({
    numbers: [smsNumber],
    text: smsText,
  });
};