import React from 'react';
import {
  Typography,
  Box,
  BoxProps,
  Button,
  Stack,
  ToggleButton,
} from '@mui/material';
import { Delete, Edit, EmailTwoTone, Textsms } from '@mui/icons-material';
import { Contacts } from '@capacitor-community/contacts';

import { DialogContext, FeedbackContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';

import { useUser } from '@modules/Users/hooks/useUser';

import { copyToClipboard } from '@shared/helpers/navigator';
import { isNative } from '@shared/helpers/environment';
import { blue, darkBlue } from '@shared/theme/palette';

import { sendSms } from '../helpers/mobile';
import { useContactInvitation } from '../hooks/useContactInvitation';
import { useContactCrud } from '../hooks/useContactCrud';

interface Props extends BoxProps {
  contact: Contacts.Contact;
  onSwiped?: any; // Dirty fix to handle deleting withing swipable stack
}

export const ContactLargeCard: React.FC<Props> = ({ contact, onSwiped, ...props }) => {

  const { openDialog, asyncConfirmation } = React.useContext(DialogContext);
  const { triggerFeedback } = React.useContext(FeedbackContext);

  const dictionary = useDictionary();

  const { inviteContactBySms } = useContactInvitation();
  const { deleteContact } = useContactCrud();

  const { user } = useUser({ id: contact.linkedUser as unknown as string }, { enabled: !!contact.linkedUser });

  const [image, setImage] = React.useState<string>();

  const handleCopyToClipboard = React.useCallback(async (text: string, feedbackText: string) => {
    await copyToClipboard(text);

    triggerFeedback({
      severity: 'success',
      message: feedbackText,
    });
  }, [triggerFeedback]);

  const onSendMessage = React.useCallback(async () => {
    sendSms(`${contact?.cel || contact?.tel}`);
  }, [contact]);

  const invite: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (contact) {
      inviteContactBySms(
        contact,
      );
    }
  }, [contact, inviteContactBySms]);

  const onPhoneButtonClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (isNative()) {
      onSendMessage();
    } else {
      handleCopyToClipboard(contact?.cel || contact?.tel || '', dictionary.users.profile.clipboardSaved('Phone number'));
    }
  }, [contact, onSendMessage, handleCopyToClipboard, dictionary]);

  const onDelete = React.useCallback(async () => {
    const userConfirmed = await asyncConfirmation({ title: dictionary.contacts.edit.deleteConfirmation, content: ' ' });
    if (!contact || !userConfirmed) {
      return false;
    }
    return deleteContact(contact.id).then(() => onSwiped());
  }, [contact, deleteContact, asyncConfirmation, dictionary, onSwiped]);

  const getImage = React.useCallback(async () => {
    if (contact.externalId && isNative()) {

      const result = await Contacts.getContact({
        contactId: contact.externalId,
        projection: {
          image: true,
        },
      });

      
      if (result.contact.image?.base64String?.length) {
        setImage(result.contact.image?.base64String as string);
      } else if(user?.profilePictureSizes?.[500]) {
        setImage(user.profilePictureSizes?.[500]);
      }
    } else if(user?.profilePictureSizes?.[500]) {
      setImage(user.profilePictureSizes?.[500]);
    }
  }, [contact.externalId, user]);

  React.useEffect(() => {
    getImage();
  }, [getImage]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      minHeight="60vh"
      borderRadius={4}
      sx={{
        backgroundColor: blue,
        color: 'common.white',
        backgroundImage: `url(${contact.image || image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        overflow: 'hidden',
      }}
      {...props}
    >
      <Stack width="100%" px={3} p={2} direction="row" justifyContent="space-between" alignItems="center">
        <Stack width="100%" spacing={1} direction="row" justifyContent="space-between" alignItems="center">
          <Box>
            {isNative() && (
              <Button color="primary" variant="contained" size="small" onClick={invite}>
                Invite
              </Button>
            )}
          </Box>
          <Stack spacing={1} direction="row">
            <ToggleButton value="edit" color="secondary" size="small" onClick={(e) => [e.stopPropagation(), openDialog('editContact', { contactId: contact.id })]}>
              <Edit/>
            </ToggleButton>
            <ToggleButton value="edit" color="secondary" size="small" onClick={(e) => [e.stopPropagation(), onDelete()]}>
              <Delete/>
            </ToggleButton>
          </Stack>
        </Stack>
      </Stack>
      <Box mt="auto" width="100%" p={2} pt={6} sx={theme => ({ background: `linear-gradient(to bottom, transparent, ${darkBlue})` })}>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Stack mt="auto" sx={{ textShadow: '0 0 4px rgba(0, 0, 0, 0.8)' }}>
            <Typography variant="h1" fontSize={32} fontWeight={600}>
              {contact.displayName}
            </Typography>
            <Typography variant="body1" fontWeight={500} color="primary.light" noWrap>
              {contact.location?.title || 'Unknown'}
            </Typography>
            {contact.note && (
              <Typography variant="body2" color="secondary.light" mt={1} noWrap>
                {contact.note}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Box
          pt={2}
        >
          <Box display="flex" gap={1} flexDirection="row" flexWrap="wrap">
            {contact.email && (
              <Button
                href={`mailto:${contact.email}`}
                variant="contained"
                color="primary"
                startIcon={<EmailTwoTone/>}
                onClick={e => e.stopPropagation()}
              >
                Email
              </Button>
            )}
            {(contact.cel || contact.tel) && (
              <Button onClick={onPhoneButtonClick} variant="contained" color="primary" startIcon={<Textsms/>}>{isNative() ? 'SMS' : 'Phone'}</Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ContactLargeCardSkeleton: React.FC = () => {
  return null;
};
