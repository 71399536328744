import React from 'react';
import { motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import { alpha, Typography } from '@mui/material';

interface Props {
  onSwiped?: () => void,
  onSwipeLeft?: () => void,
  onSwipeRight?: () => void,
  onClick?: () => void,
  leftLabel?: string;
  rightLabel?: string;
  resetDragAnimationOnRightSwipe?: boolean,
  resetDragAnimationOnLeftSwipe?: boolean,
}

export const SwipableCard: React.FC<React.PropsWithChildren<Props>> = ({ 
  children, 
  onClick, 
  onSwiped, 
  onSwipeLeft, 
  onSwipeRight,
  leftLabel = 'SKIP',
  rightLabel = 'NOTIFY',
}) => {
  const [swipable, setSwipable] = React.useState(true);
  const motionValue = useMotionValue(0);
  const rotateValue = useTransform(motionValue, [-200, 200], [-20, 20]);
  const opacityValue = useTransform(
    motionValue,
    [-200, -150, 0, 150, 200],
    [0, 1, 1, 1, 0],
  );

  // New transforms for overlay opacity
  const leftOverlayOpacity = useTransform(
    motionValue,
    [-200, -100, 0],
    [1, 1, 0],
  );
  const rightOverlayOpacity = useTransform(
    motionValue,
    [0, 100, 200],
    [0, 1, 1],
  );

  const animControls = useAnimation();

  return (
    <motion.div
      drag="x"
      style={{
        width: '100%',
        height: '100%',
        x: motionValue,
        rotate: rotateValue,
        opacity: opacityValue,
        position: 'relative',
      }}
      dragConstraints={{ left: -1000, right: 1000 }}
      animate={animControls}
      onClick={onClick}
      onDragEnd={(event, info) => {
        if (swipable) {
          if (Math.abs(info.offset.x) <= 100) {
            animControls.start({ x: 0 });
          } else {
            setSwipable(false);
            const wasSwipedLeft = info.offset.x < 0;
            animControls.start({ x: wasSwipedLeft ? -200 : 200 }).then(async () => {
              if (wasSwipedLeft && onSwipeLeft) {
                await onSwipeLeft();
              }
              if (!wasSwipedLeft && onSwipeRight) {
                await onSwipeRight();
              }
              if (onSwiped) {
                onSwiped();
              }
            });
          }
        }
      }}
    >
      {React.cloneElement(children as React.ReactElement<any>, {
        onSwiped,
      })}
      <motion.div
        style={{
          zIndex: 9999,
          position: 'absolute',
          top: 40,
          right: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: leftOverlayOpacity,
          pointerEvents: 'none',
        }}
      >
        <Typography
          variant="h1"
          component="span"
          sx={theme => ({
            fontSize: 48,
            fontWeight: 600,
            color: theme.palette.error.main,
            borderWidth: 4,
            borderStyle: 'solid',
            borderColor: theme.palette.error.main,
            py: 1,
            px: 3,
            borderRadius: 4,
            transform: 'rotate(15deg)',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            textShadow: '0 0 4px rgba(0, 0, 0, 0.5)',
            letterSpacing: -2,
            backgroundColor: alpha(theme.palette.error.light, 0.1),
          })}
        >
          {leftLabel}
        </Typography>
      </motion.div>
      <motion.div
        style={{
          zIndex: 9999,
          position: 'absolute',
          top: 40,
          left: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: rightOverlayOpacity,
          pointerEvents: 'none',
        }}
      >
        <Typography
          variant="h1"
          component="span"
          sx={theme => ({
            fontSize: 48,
            fontWeight: 600,
            color: theme.palette.success.main,
            borderWidth: 4,
            borderStyle: 'solid',
            borderColor: theme.palette.success.main,
            py: 1,
            px: 3,
            borderRadius: 4,
            transform: 'rotate(-15deg)',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
            textShadow: '0 0 4px rgba(0, 0, 0, 0.5)',
            letterSpacing: -2,
            backgroundColor: alpha(theme.palette.success.light, 0.1),
          })}
        >
          {rightLabel}
        </Typography>
      </motion.div>
    </motion.div>
  );
};